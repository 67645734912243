import React from 'react'
import RichTextModule from './RichTextModule'
import ImageGalleryModule from './ImageGalleryModule'
import TextWithGalleryModule from './TextWithGalleryModule'
import TextWithImageModule from './TextWithImageModule'
import QuoteModule from './QuoteModule'
import TeamModule from './TeamModule'

// dev
const PlaceholderModule = ({ moduleType }) => (
  <div className="bg-red-200 px-4 py-20">
    <h2>module Type: {moduleType}</h2>
  </div>
)

export default function Modules({ modules }) {
  const hasModules = modules && modules.some(module => module._type)
  if (!hasModules) return null

  return modules
    .filter(module => module._type)
    .map((module, index) => {
      const { _type: moduleType, _key: key } = module

      switch (moduleType) {
        case 'richTextModule':
          return <RichTextModule key={key} module={module} />
        case 'galleryModule':
          return <ImageGalleryModule key={key} module={module} />
        case 'textWithGalleryModule':
          return (
            <TextWithGalleryModule
              key={key}
              module={module}
              autoTextPosition={index % 2 === 0 && 'left'}
            />
          )
        case 'textWithImageModule':
          return (
            <TextWithImageModule
              key={key}
              module={module}
              autoTextPosition={index % 2 === 0 && 'left'}
            />
          )
        case 'quoteModule':
          return <QuoteModule key={key} module={module} />
        case 'teamModule':
          return <TeamModule key={key} module={module} />
        default:
          throw new Error(`"${moduleType}" is not an unknown module`)
      }
    })
}
