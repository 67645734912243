import './TextWithGalleryModule.css'
import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { hasGalleryImage } from '../ImageGallery'
import PageSection from '../PageSection'
import { cx } from '../../lib/styles'
import RichText from '../RichText'

const Gallery = ({ gallery }) => {
  const filteredGallery = gallery.filter(
    ({ mainImageDocument }) => !!mainImageDocument
  )
  // @TODO: consider what happens with 3 or more images.
  return (
    <div
      className={cx(
        'textWithGallery--gallery',
        gallery.length === 2 && 'textWithGallery--gallery__two'
      )}
    >
      {filteredGallery.map(
        ({ mainImageDocument: { alt, mainImage, _id: id } }) => (
          <Image key={id} fluid={mainImage.asset.fluid} alt={alt} />
        )
      )}
    </div>
  )
}

export default function TextWithGalleryModule({ module, autoTextPosition }) {
  const {
    gallery,
    _rawBasicRichText: richTextBlocks,
    textPosition: userTextPosition,
  } = module

  const textPosition =
    userTextPosition && userTextPosition !== 'default'
      ? userTextPosition
      : autoTextPosition

  if (!hasGalleryImage(gallery)) return null

  return (
    <PageSection>
      <div className="textWithGallery">
        <div className="mb-6 md:mb-8 lg:mb-0">
          <Gallery gallery={gallery} />
        </div>
        <div
          className={cx(
            textPosition === 'left' && 'textWithGallery--text-left'
          )}
        >
          <RichText blocks={richTextBlocks} excludeWrapper />
        </div>
      </div>
    </PageSection>
  )
}

export const query = graphql`
  fragment TextWithGalleryModuleFields on SanityTextWithGalleryModule {
    _key
    _type
    _rawBasicRichText
    textPosition
    gallery {
      mainImageDocument {
        _id
        alt
        mainImage {
          asset {
            fluid(maxWidth: 620, maxHeight: 620) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
