import React from 'react'
import './ContactButtons.css'
import CopyButton from './CopyButton'
import { cx } from '../lib/styles'

export default function ContactButtons({
  contactType,
  value,
  href,
  action,
  ActionIcon,
  className,
}) {
  return (
    <div className={cx('contactButtons', className)}>
      <p className="contactButtons--info">{value}</p>
      <div className="contactButtons--buttons">
        <a
          className="contactButtons--button"
          href={href}
          aria-label={`feel free to ${action} us at ${value}`}
        >
          <ActionIcon className="icon" />
          {action}
        </a>
        <CopyButton
          className="contactButtons--button"
          label={contactType}
          textToCopy={value}
        />
      </div>
    </div>
  )
}
