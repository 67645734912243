import React from 'react'
import { graphql } from 'gatsby'
import './Hero.css'
import MainHero from './MainHero'

const hasHeroContent = (heroContent, defaultTitle) => {
  if (!heroContent) return false

  return (
    heroContent.title ||
    heroContent._rawText ||
    heroContent.headerImageDocument ||
    heroContent.mainImageDocument ||
    (heroContent.usePageTitle && defaultTitle)
  )
}

export default function Hero({ hero, lowKeyHero, pageTitle }) {
  if (hasHeroContent(hero, pageTitle)) {
    return <MainHero hero={hero} pageTitle={pageTitle} />
  }

  // TODO: create a secondary hero for less prominent pages called LowKeyHero
  if (hasHeroContent(lowKeyHero, pageTitle)) {
    return <MainHero hero={lowKeyHero} pageTitle={pageTitle} />
  }

  return null
}

export const heroFields = graphql`
  fragment HeroFields on SanityHero {
    title
    usePageTitle
    _rawText
    headerImageDocument {
      ...HeaderImage
    }
  }
`

export const heroLowKeyFields = graphql`
  fragment LowKeyHeroFields on SanityLowKeyHero {
    title
    usePageTitle
    _rawText
    mainImageDocument {
      ...MainImageDefaultSquare
    }
  }
`
