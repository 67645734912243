import React from 'react'
import './TextWithMedia.css'
import PageSection from '../PageSection'
import { cx } from '../../lib/styles'
import RichText from '../RichText'

export default function TextWithMedia({ textPosition, richTextBlocks, media }) {
  return (
    <PageSection>
      <div className="textWithMedia">
        <div className="mb-6 md:mb-8 lg:mb-0">{media}</div>
        <div
          className={cx(textPosition === 'left' && 'textWithMedia--text-left')}
        >
          <RichText blocks={richTextBlocks} excludeWrapper />
        </div>
      </div>
    </PageSection>
  )
}
