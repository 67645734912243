import './Nav.css'
import '@reach/dialog/styles.css'
import '@reach/menu-button/styles.css'
import React, { useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import { AiFillCloseCircle } from 'react-icons/ai'
import startCase from 'lodash.startcase'
import { getUrl } from '../lib/slug'
import Email from './Email'
import Phone from './Phone'
import Heading from './Heading'

const Title = ({ company, absolute, isLinkWrapped }) => {
  const classes = ['nav__logo']

  if (absolute) {
    classes.push('nav__logo--absolute')
  } else {
    classes.push('nav__logo--default')
  }

  if (!isLinkWrapped) classes.push('nav__logo--link-unwrapped')

  return (
    <h1 className={classes.join(' ')}>
      <span className={`block ${!absolute && 'bg-white'}`}>{company.name}</span>{' '}
      <span className={`text-lg font-normal ${!absolute && 'bg-white'}`}>
        {company.subtitle}
      </span>
    </h1>
  )
}

const ItemWithSubMenu = ({ absolute, itemTitle, subNavItems }) => {
  const location = useLocation()

  const itemStyles = absolute
    ? 'nav__item nav__item--absolute'
    : 'nav__item nav__item--default'

  return (
    <li className="nav__item-wrapper">
      <Menu>
        <MenuButton className={itemStyles}>{itemTitle}</MenuButton>
        <MenuList className="nav__submenu">
          {subNavItems.map(({ _key: key, title, internalLink }) => {
            const { _type: type, slug } = internalLink
            const internalUrl = getUrl(type, slug.current)
            const isCurrent = location.pathname === internalUrl

            return (
              <MenuItem
                key={key}
                className="nav__subItem"
                disabled={isCurrent}
                onSelect={() => {
                  if (isCurrent) return
                  navigate(internalUrl)
                }}
              >
                {title || internalLink.title || startCase(slug.current)}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </li>
  )
}

export default function Nav({ absolute, hideTitle }) {
  const location = useLocation()
  const {
    sanityCompany: company,
    sanitySettings: settings,
  } = useStaticQuery(graphql`
    query {
      sanityCompany {
        id
        name
        subtitle
      }
      sanitySettings {
        mainNav {
          navItems {
            _key
            title
            internalLink {
              ... on SanityPage {
                title
                id
                _type
                slug {
                  current
                }
              }
              ... on SanityService {
                title
                id
                _type
                slug {
                  current
                }
              }
            }
            subNavItems {
              _key
              title
              internalLink {
                ... on SanityPage {
                  title
                  id
                  _type
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  title
                  id
                  _type
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const mainNav = settings?.mainNav?.navItems || []
  const navItems = mainNav.filter(
    ({ internalLink, subNavItems }) => !!internalLink || !!subNavItems
  )

  const [showContactInfo, setShowContactInfo] = useState(false)

  const itemStyles = absolute
    ? 'nav__item nav__item--absolute'
    : 'nav__item nav__item--default'

  return (
    <nav className={`nav${absolute ? ' nav--absolute' : ''}`}>
      {!hideTitle && location.pathname !== '/' ? (
        <Link to="/" className="nav__logo-link">
          <Title company={company} absolute={absolute} isLinkWrapped />
        </Link>
      ) : !hideTitle ? (
        <Title company={company} absolute={absolute} />
      ) : null}

      {navItems.length && (
        <ul className="nav__menu">
          {navItems.map(({ _key: key, title, internalLink, subNavItems }) => {
            const itemTitle =
              title ||
              internalLink.title ||
              startCase(internalLink.slug.current)

            const internalUrl =
              internalLink &&
              getUrl(internalLink._type, internalLink.slug.current)
            const isCurrent = location.pathname === internalUrl

            if (subNavItems.length) {
              return (
                <ItemWithSubMenu
                  key={key}
                  itemTitle={itemTitle}
                  absolute={absolute}
                  subNavItems={subNavItems}
                />
              )
            }

            return (
              <li key={key} className="nav__item-wrapper">
                {isCurrent ? (
                  <div className={`${itemStyles} nav__item--selected`}>
                    {itemTitle}
                  </div>
                ) : (
                  <Link className={itemStyles} to={internalUrl}>
                    {itemTitle}
                  </Link>
                )}
              </li>
            )
          })}
          <li className="nav__item-wrapper">
            <button
              type="button"
              onClick={() => setShowContactInfo(true)}
              className={itemStyles}
              aria-label="open our contact information"
            >
              Contact
            </button>
            <DialogOverlay
              isOpen={showContactInfo}
              onDismiss={() => setShowContactInfo(false)}
              style={{
                zIndex: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DialogContent
                aria-label="our contact information"
                className="contactDialog"
              >
                <button
                  type="button"
                  aria-label="close contact dialog"
                  onClick={() => setShowContactInfo(false)}
                  className="absolute top-2 left-2 rounded-full"
                >
                  <AiFillCloseCircle className="text-grey-900" />
                </button>
                <Heading as="h3" className="mb-4 text-center">
                  Contact
                </Heading>
                <div className="mainContent">
                  <Phone className="mb-4 mx-auto" />
                  <Email className="mx-auto" />
                </div>
              </DialogContent>
            </DialogOverlay>
          </li>
        </ul>
      )}
    </nav>
  )
}
