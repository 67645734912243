import React from 'react'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import BlockContent from '@sanity/block-content-to-react'
import Image from 'gatsby-image'
import Heading from './Heading'

const config = {
  sanity: {
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
  },
}

const RichHeading = ({ children, level }) => (
  <Heading as={`h${level}`} className="mb-2 mt-20 md:mb-4 md:mt-28 first:mt-0">
    {children}
  </Heading>
)

const Paragraph = ({ children }) => <p className="mb-6 md:mb-8">{children}</p>

const BlockRenderer = props => {
  const { style } = props.node
  const isHeading = /^h\d/.test(style)

  if (isHeading) {
    const level = style.replace(/[^\d]/g, '')
    return <RichHeading level={level}>{props.children}</RichHeading>
  }
  if (style === 'normal') {
    return <Paragraph>{props.children}</Paragraph>
  }

  return BlockContent.defaultSerializers.types.block(props)
}

const serializer = {
  types: {
    block: BlockRenderer,
    mainImageDocument: ({ node }) => {
      const fluidProps = getFluidGatsbyImage(
        node.mainImage.asset,
        {
          maxWidth: 600,
          maxHeight: 600,
        },
        config.sanity
      )

      return (
        <Image
          fluid={fluidProps}
          alt={node.alt}
          className="mx-auto mb-8 max-w-xl"
        />
      )
    },
  },
}

export default function RichText({ blocks, excludeWrapper }) {
  if (excludeWrapper) {
    return <BlockContent blocks={blocks} serializers={serializer} />
  }
  return (
    <div className="container max-w-2xl mx-auto">
      <BlockContent blocks={blocks} serializers={serializer} />
    </div>
  )
}
