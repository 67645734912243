import React, { useEffect, useRef, useState } from 'react'
import { HiClipboardCopy, HiCheck } from 'react-icons/hi'

export default function CopyButton({ className, label, textToCopy }) {
  const [copySuccess, setCopySuccess] = useState(false)
  const buttonRef = useRef()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      buttonRef.current.focus()
      setCopySuccess(true)
    })
  }

  useEffect(() => {
    if (!copySuccess) return

    const timer = setTimeout(() => {
      setCopySuccess(false)
    }, 2000)

    return () => timer && clearTimeout(timer)
  }, [copySuccess])

  return (
    <button
      ref={buttonRef}
      type="button"
      aria-labelledby={`copy ${label} to clipboard`}
      onClick={copyToClipboard}
      className={className}
    >
      {copySuccess ? (
        <HiCheck className="icon text-green-300" />
      ) : (
        <HiClipboardCopy className="icon" />
      )}
      copy {label}
    </button>
  )
}
