import React from 'react'
import { graphql } from 'gatsby'
import PageSection, { SectionTextContainer } from '../PageSection'
import { SectionHeading } from '../Heading'
import ImageGallery from '../ImageGallery'

export default function ImageGalleryModule({ module }) {
  const { title, subtitle, gallery, displayCaption } = module
  const hasText = title || subtitle

  if (!gallery || !gallery.length) return null

  return (
    <PageSection includeInnerWrapper={false}>
      {hasText && (
        <SectionTextContainer>
          {title && (
            <SectionHeading hasSubText={!!subtitle}>{title}</SectionHeading>
          )}
          {subtitle && <p className="text-gray-600">{subtitle}</p>}
        </SectionTextContainer>
      )}
      <ImageGallery gallery={gallery} displayCaptions={displayCaption} />
    </PageSection>
  )
}

export const query = graphql`
  fragment GalleryModuleFields on SanityGalleryModule {
    _key
    _type
    title
    subtitle
    displayCaption
    gallery {
      ...GalleryItemFields
    }
  }
`
