import React, { useEffect, useState } from 'react'
import './Footer.css'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { FaInstagram } from 'react-icons/fa'
import { FiAward } from 'react-icons/fi'
import Email from './Email'
import Phone from './Phone'
import { cx } from '../lib/styles'

const useCompany = () => {
  const { sanityCompany: company } = useStaticQuery(graphql`
    query {
      sanityCompany {
        name
        phone
        email
        instagram
        license
        businessCard {
          asset {
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  return company
}

const useYear = () => {
  const [year, setYear] = useState(null)

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])

  return year
}

export default function Footer() {
  const company = useCompany()
  const year = useYear()

  const hasContact = company.phone || company.email
  const hasSocialMedia = company.instagram

  return (
    <footer className="footer">
      <div className="footer--inner">
        <div className="footer--right">
          <div className="footer--image">
            <Image
              fluid={company.businessCard.asset.fluid}
              alt={`${company.name} business card`}
            />
          </div>
          <div className="footer--content">
            <div className="flex items-center justify-center md:justify-start">
              <FiAward className="mr-2" />
              <p>Our license: {company.license}</p>
            </div>
            {hasSocialMedia && (
              <>
                <ul>
                  {company.instagram && (
                    <li className="footer--item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://www.instagram.com/${company.instagram}`}
                        className="footer--link"
                      >
                        <FaInstagram className="mr-2" />@{company.instagram}
                      </a>
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
          <div className="flex flex-col items-center lg:items-start" />
        </div>
        <div className="footer--left">
          <h2 className="footer--heading">Work with Us</h2>
          <p className="mb-8">
            We would love to work with you to make you next dream home
            renovation a reality. Feel free to contact us for a free quote or
            any other information.
          </p>
          {hasContact && (
            <div className="footer--contact">
              {company.phone && <Phone />}
              {company.email && <Email />}
            </div>
          )}
        </div>
      </div>
      <p className="text-center p-4 text-sm">
        Copyright &copy; {year} {company.name}. All Rights Reserved
      </p>
    </footer>
  )
}
