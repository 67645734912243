import React from 'react'
import { graphql } from 'gatsby'
import { FaQuoteLeft } from 'react-icons/fa'
import Section from '../PageSection'

export default function QuoteModule({ module }) {
  const { quote, client, subtext } = module

  if (!quote) return null

  return (
    <Section className="text-lg md:text-2xl">
      <figure className="relative max-w-text mx-auto text-center">
        <FaQuoteLeft
          className="mx-auto mb-8 md:mb-12 text-indigo-500"
          size="2em"
        />
        <blockquote>{quote}</blockquote>
        {client && (
          <figcaption className="text-sm md:text-base mt-10">
            <cite>{client}</cite>
            {subtext && <p className="text-gray-700">{subtext}</p>}
          </figcaption>
        )}
      </figure>
    </Section>
  )
}

export const quoteModuleFields = graphql`
  fragment QuoteModuleFields on SanityQuoteModule {
    _key
    _type
    quote
    client
    subtext
  }
`
