import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AiOutlinePhone } from 'react-icons/ai'
import kebabcase from 'lodash.kebabcase'
import ContactButton from './ContactButtons'

export default function Phone(props) {
  const { sanityCompany: company } = useStaticQuery(graphql`
    query {
      sanityCompany {
        phone
      }
    }
  `)

  if (!company.phone) return null

  return (
    <ContactButton
      contactType="number"
      value={company.phone}
      href={`tel:1-${kebabcase(company.phone)}`}
      action="call"
      ActionIcon={AiOutlinePhone}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
