import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import TextWithMedia from './TextWithMedia'

export default function TextWithImageModule({ module, autoTextPosition }) {
  const {
    mainImageDocument,
    _rawBasicRichText: richTextBlocks,
    textPosition: userTextPosition,
  } = module

  const textPosition =
    userTextPosition && userTextPosition !== 'default'
      ? userTextPosition
      : autoTextPosition

  if (!mainImageDocument) return null

  return (
    <TextWithMedia
      textPosition={textPosition}
      richTextBlocks={richTextBlocks}
      media={
        <Image
          className="max-w-2xl mx-auto"
          alt={mainImageDocument.alt}
          fluid={mainImageDocument.mainImage.asset.fluid}
        />
      }
    />
  )
}

export const query = graphql`
  fragment TextWithImageModuleFields on SanityTextWithImageModule {
    _key
    _type
    _rawBasicRichText
    textPosition
    mainImageDocument {
      ...MainImageDefaultSquare
    }
  }
`
