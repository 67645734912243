import React from 'react'
import Image from 'gatsby-image'
import BlockContent from '@sanity/block-content-to-react'
import { PageHeading } from '../Heading'

const heroContainerStyles = ['relative', 'hero'].join(' ')
const imageStyles = ['hero__headerImage'].join(' ')

const overlayLargeContentStyles = [
  'relative',
  '-top-16',
  '-mb-16',

  'lg:mb-0',
  'lg:absolute',
  'lg:inset-0',
  'lg:flex',
  'lg:justify-end',

  'mx-auto',
  'max-w-7xl',
].join(' ')
const overlaySmallContentStyles = [
  'md:absolute',
  'md:inset-0',

  'md:flex',
  'md:justify-center',
  'md:items-center',
].join(' ')

const textContentNoOverlayStyles = [
  // styles here
  'px-6',
  'pt-12',
  'pb-12',
].join(' ')
const textOverlayedLargeStyles = [
  'px-6',
  'pt-12',
  'pb-16',
  'lg:py-28',

  'mx-2',
  'sm:mx-4',
  'md:mx-8',
  'lg:mx-0',

  'bg-white',
  'lg:bg-black',
  'lg:bg-opacity-50',

  'shadow-lg',
  'lg:shadow-none',

  'lg:text-white',
  'lg:w-1/2',
].join(' ')
const textOverlayedSmallStyles = [
  'px-4',
  'py-10',
  'md:p-8',

  'md:text-white',
  'md:bg-black',
  'md:bg-opacity-50',
].join(' ')

const HeroParagraph = ({ children }) => (
  <p className="max-w-text mx-auto lg:text-xl">{children}</p>
)

const TextContent = ({ title, richText, isOverlayed }) => {
  let textContentStyles
  if (isOverlayed) {
    textContentStyles = richText
      ? textOverlayedLargeStyles
      : textOverlayedSmallStyles
  } else {
    textContentStyles = textContentNoOverlayStyles
  }

  return (
    <div className={textContentStyles}>
      {title && <PageHeading hasSubText={!!richText}>{title}</PageHeading>}
      {!!richText && (
        <BlockContent
          blocks={richText}
          serializers={{
            types: {
              block: HeroParagraph,
            },
          }}
        />
      )}
    </div>
  )
}

export default function MainHero({ hero, pageTitle }) {
  const {
    title: heroTitle,
    usePageTitle,
    headerImageDocument,
    _rawText: richText,
  } = hero
  const titleToUse = usePageTitle && pageTitle ? pageTitle : heroTitle
  if (!headerImageDocument && !titleToUse && !richText) return null

  const overlaySyles = richText
    ? overlayLargeContentStyles
    : overlaySmallContentStyles

  return (
    <section className={heroContainerStyles}>
      {headerImageDocument ? (
        <>
          <Image
            className={imageStyles}
            alt={headerImageDocument.alt}
            fluid={headerImageDocument.headerImage.asset.fluid}
          />
          {!!titleToUse || !!richText ? (
            <div className={overlaySyles}>
              <TextContent isOverlayed title={titleToUse} richText={richText} />
            </div>
          ) : null}
        </>
      ) : (
        <TextContent title={titleToUse} richText={richText} />
      )}
    </section>
  )
}
