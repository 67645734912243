import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

export default function Seo({ title, description, imageUrl }) {
  const {
    site: { siteMetadata },
    sanityCompany: company,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          url
        }
      }
      sanityCompany {
        name
        metaDescription
        businessCard {
          asset {
            url
          }
        }
        logo {
          profileImage {
            asset {
              url
            }
          }
        }
      }
    }
  `)

  const tagTitle = title || company.name || siteMetadata.defaultTitle
  const metaDescription = description || company.metaDescription || ''
  const metaImage = imageUrl || company.businessCard.asset.url

  return (
    <Helmet
      titleTemplate={`%s | ${company.name || siteMetadata.defaultTitle}`}
      defaultTitle={tagTitle}
    >
      <html lang="en" />

      {title && <title>{title}</title>}

      {/* general tags */}
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaImage} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={siteMetadata.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={tagTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={tagTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={metaImage} />
    </Helmet>
  )
}
