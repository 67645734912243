import React from 'react'
import Seo from './Seo'
import Hero from './hero/Hero'
import Nav from './Nav'
import Modules from './modules/Modules'
import Footer from './Footer'

export default function Page({ title, hero, lowKeyHero, modules }) {
  const hasHeroImage = hero && hero.headerImageDocument

  return (
    <div>
      <Seo title={title} />
      <Nav absolute={hasHeroImage} />
      <Hero hero={hero} lowKeyHero={lowKeyHero} pageTitle={title} />
      <Modules modules={modules} />
      <Footer />
    </div>
  )
}
