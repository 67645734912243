import React, { useState, useRef } from 'react'
import '@reach/dialog/styles.css'
import 'swiper/swiper.scss'
import './ModalGallery.css'
import SwiperCore, { Zoom, Keyboard, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import {
  AiFillCloseCircle,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai'
import { cx } from '../lib/styles'

SwiperCore.use([Zoom, Keyboard, Lazy])

export default function ModalGallery({ gallery, onDismiss, initialSlide }) {
  const swiperRef = useRef()
  const [zoomed, setZoomed] = useState(false)
  const [numSlides, setNumSlides] = useState()
  const [currentSlide, setCurrentSlide] = useState()

  const onAfterInit = swiper => {
    if (!swiper) return

    swiperRef.current = swiper
    setNumSlides(swiper.slides.length)
    setCurrentSlide(swiper.realIndex + 1)
  }

  const toggleZoom = () => {
    const swiper = swiperRef.current
    if (!swiper) return
    swiper.zoom.toggle()
  }

  const nextSlide = () => {
    const swiper = swiperRef.current
    if (!swiper) return
    swiper.slideNext()
  }

  const prevSlide = () => {
    const swiper = swiperRef.current
    if (!swiper) return
    swiper.slidePrev()
  }

  const onZoomChange = (_, scale) => setZoomed(scale > 1)

  return (
    <DialogOverlay className="modalGallery" onDismiss={onDismiss}>
      <DialogContent
        className="modalGallery__content"
        aria-label="image gallery"
      >
        <div className="modalGallery__swiper-content">
          <header
            className={cx(
              'modalGallery__header',
              zoomed && 'modalGallery__header--zoomed'
            )}
          >
            <ButtonGroup>
              <HeaderButton
                onClick={onDismiss}
                aria-label="close image gallery"
              >
                <AiFillCloseCircle />
              </HeaderButton>
              <HeaderButton
                onClick={toggleZoom}
                aria-label={`zoom ${zoomed ? 'out' : 'in'}`}
              >
                {zoomed ? <AiOutlineZoomOut /> : <AiOutlineZoomIn />}
              </HeaderButton>
            </ButtonGroup>
            <div>
              {numSlides && currentSlide && (
                <p>
                  {currentSlide} / {numSlides}
                </p>
              )}
            </div>
            <ButtonGroup>
              <HeaderButton aria-label="previous image" onClick={prevSlide}>
                <AiOutlineLeft />
              </HeaderButton>
              <HeaderButton aria-label="next image" onClick={nextSlide}>
                <AiOutlineRight className="right" />
              </HeaderButton>
            </ButtonGroup>
          </header>
          <Swiper
            keyboard
            zoom={{ maxRatio: 3 }}
            onZoomChange={onZoomChange}
            onAfterInit={onAfterInit}
            lazy={{ loadPrevNext: true }}
            preloadImages={false}
            onRealIndexChange={swiper => setCurrentSlide(swiper.realIndex + 1)}
            initialSlide={initialSlide}
          >
            {gallery.map(({ mainImageDocument }) => (
              <SwiperSlide key={mainImageDocument._id} zoom>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg=="
                  className="swiper-lazy"
                  data-src={mainImageDocument.modalImage.asset.url}
                  alt={mainImageDocument.alt}
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}

function HeaderButton({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button type="button" className="modalGallery__button" {...props}>
      {children}
    </button>
  )
}

function ButtonGroup({ children }) {
  return <div className="modalGallery__button-group">{children}</div>
}
