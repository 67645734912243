import './ImageGallery.css'
import React, { useState } from 'react'
import Image from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import { getUrl } from '../lib/slug'
import Heading from './Heading'
import ModalGallery from './ModalGallery'

export const hasGalleryImage = gallery =>
  gallery && gallery.some(({ mainImageDocument }) => !!mainImageDocument)

const ImageAction = ({ children, link, showModal }) => {
  if (!link) {
    return (
      <button
        type="button"
        className="imageGallery__image-wrapper imageGallery__image-wrapper--button"
        onClick={showModal}
      >
        {children}
      </button>
    )
  }
  return (
    <Link
      className="imageGallery__image-wrapper imageGallery__image-wrapper--link"
      to={getUrl(link._type, link.slug.current)}
    >
      {children}
      <LinkTitle link={link} />
    </Link>
  )
}

const GalleryCell = ({ item, showCaptions, showModal }) => {
  const {
    mainImageDocument: { alt, mainImage },
    caption,
    link,
  } = item

  return (
    <>
      <div>
        {showCaptions ? (
          <div className="imageGallery__wrapper">
            <ImageAction link={link} showModal={showModal}>
              <Image fluid={mainImage.asset.fluid} alt={alt} />
            </ImageAction>
            {caption && <p>{caption}</p>}
          </div>
        ) : (
          <ImageAction link={link} showModal={showModal}>
            <Image fluid={mainImage.asset.fluid} alt={alt} />
          </ImageAction>
        )}
      </div>
    </>
  )
}

export default function Gallery({ gallery, displayCaptions }) {
  const [initialModalSlide, setInitialModalSlide] = useState(null)
  if (!hasGalleryImage(gallery)) return null

  const someCaptionsPresent = gallery.some(({ caption }) => !!caption)
  const showCaptions = displayCaptions && someCaptionsPresent
  const imageNumberClass =
    gallery.length > 2
      ? 'imageGallery--more-than-2-images'
      : 'imageGallery--2-images'
  const modalItems = gallery.filter(
    ({ mainImageDocument, link }) => !!mainImageDocument && !link
  )

  const openGalleryModal = slideIndex => {
    if (!modalItems.length) return
    setInitialModalSlide(slideIndex)
  }

  return (
    <>
      <div
        className={`imageGallery ${imageNumberClass}${
          showCaptions ? ' imageGallery--pushed-image' : ''
        }`}
      >
        {gallery
          .filter(({ mainImageDocument }) => !!mainImageDocument)
          .map((item, i) => (
            <GalleryCell
              key={item.mainImageDocument._id}
              item={item}
              showCaptions={showCaptions}
              showModal={() => openGalleryModal(i)}
            />
          ))}
      </div>
      {typeof initialModalSlide === 'number' && modalItems.length && (
        <ModalGallery
          gallery={modalItems}
          onDismiss={() => setInitialModalSlide(null)}
          initialSlide={initialModalSlide}
        />
      )}
    </>
  )
}

export const mainImageGalleryFragment = graphql`
  fragment MainImageGallerySquare on SanityMainImageDocument {
    _id
    alt
    mainImage {
      asset {
        fluid(maxWidth: 400, maxHeight: 400) {
          ...GatsbySanityImageFluid
        }
      }
    }
    modalImage: mainImage {
      asset {
        url
      }
    }
  }
`

export const query = graphql`
  fragment GalleryItemFields on SanityGalleryItem {
    caption
    mainImageDocument {
      ...MainImageGallerySquare
    }
    link {
      ... on SanityPage {
        _type
        title
        slug {
          current
        }
      }
      ... on SanityService {
        _type
        title
        slug {
          current
        }
      }
    }
  }
`

function LinkTitle({ link }) {
  if (!link) return null

  return (
    <Heading as="h3" looksLike="h5" className="imageGallery__link-title">
      {link.title}
    </Heading>
  )
}
