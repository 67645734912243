/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import './Heading.css'
import { cx } from '../lib/styles'

export default function Heading({ as = 'h1', looksLike, children, className }) {
  const Tag = as

  return (
    <Tag className={cx('heading', `heading--${looksLike || as}`, className)}>
      {children}
    </Tag>
  )
}

export const PageHeading = ({ children, hasSubText }) => (
  <Heading className={cx(hasSubText && 'mb-8', 'text-center')}>
    {children}
  </Heading>
)

export const SectionHeading = ({ children, hasSubText }) => (
  <Heading as="h2" className={cx(hasSubText && 'mb-2')}>
    {children}
  </Heading>
)
