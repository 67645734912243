import React from 'react'
import './PageSection.css'
import { cx } from '../lib/styles'

export const SectionTextContainer = ({ children, center = true }) => (
  <div className={cx('mb-16', 'lg:mb-20', center && 'text-center')}>
    {children}
  </div>
)

export default function PageSection({
  children,
  className,
  innerClassName,
  includeInnerWrapper = true,
}) {
  const sectionClasses = cx('pageSection', className)

  if (!includeInnerWrapper)
    return <section className={sectionClasses}>{children}</section>

  return (
    <section className={sectionClasses}>
      <div className={cx('pageSection--inner', innerClassName)}>{children}</div>
    </section>
  )
}
