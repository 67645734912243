import React from 'react'
import './TeamModule.css'
import { graphql, useStaticQuery } from 'gatsby'
import Image from 'gatsby-image'
import PageSection, { SectionTextContainer } from '../PageSection'
import Heading, { SectionHeading } from '../Heading'
import RichText from '../RichText'

const useTeam = () => {
  const { sanityCompany: company } = useStaticQuery(graphql`
    query {
      sanityCompany {
        team {
          id
          name
          role
          _rawAbout
          profileImageDocument {
            alt
            profileImage {
              asset {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return company && company.team
}

const TeamMember = ({ person, userSettings }) => {
  const { name, role, profileImageDocument, _rawAbout: about } = person
  const { includeName, includeRole, includeAbout } = userSettings

  return (
    <div className="teamSection--member">
      <div>
        <Image
          className="teamSection--image"
          fluid={profileImageDocument.profileImage.asset.fluid}
          alt={profileImageDocument.profileImage.alt}
        />
        {includeName && includeRole && (
          <>
            {includeName && (
              <Heading as="h3" className="text-center font-semibold">
                {name}
              </Heading>
            )}
            {includeRole && <p className="text-center text-gray-600">{role}</p>}
          </>
        )}
      </div>
      {includeAbout && <RichText excludeWrapper blocks={about} />}
    </div>
  )
}

export default function TeamModule({ module }) {
  const team = useTeam()
  if (!team) return null

  return (
    <PageSection className="teamSection">
      <SectionTextContainer>
        <SectionHeading>Our Team</SectionHeading>
      </SectionTextContainer>
      <div className="teamSection--team">
        {team.map(person => (
          <TeamMember key={person.id} person={person} userSettings={module} />
        ))}
      </div>
    </PageSection>
  )
}

export const teamModuleFields = graphql`
  fragment TeamModuleFields on SanityTeamModule {
    _key
    _type
    includeName
    includeRole
    includeAbout
  }
`
