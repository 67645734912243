import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AiOutlineMail } from 'react-icons/ai'
import ContactButton from './ContactButtons'

export default function Email(props) {
  const { sanityCompany: company } = useStaticQuery(graphql`
    query {
      sanityCompany {
        email
      }
    }
  `)

  if (!company.email) return null

  return (
    <ContactButton
      contactType="email"
      value={company.email}
      href={`mailto:${company.email}`}
      action="email"
      ActionIcon={AiOutlineMail}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
