import React from 'react'
import { graphql } from 'gatsby'
import RichText from '../RichText'
import PageSection from '../PageSection'

export default function RichTextModule({ module }) {
  const { _rawRichText: richTextBlocks } = module

  return (
    <PageSection>
      <RichText blocks={richTextBlocks} />
    </PageSection>
  )
}

export const query = graphql`
  fragment RichTextModuleFields on SanityRichTextModule {
    _key
    _type
    _rawRichText(resolveReferences: { maxDepth: 5 })
  }
`
